var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logins"},[_vm._m(0),_c('div',{staticClass:"boxs"},[_c('el-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginType),expression:"loginType"}],ref:"form",staticClass:"login-form",attrs:{"rules":_vm.rules,"model":_vm.form}},[_c('div',{staticClass:"formTop"},[_c('div',{staticClass:"login-title"},[_vm._v("邮件注册")])]),_c('el-form-item',{staticStyle:{"margin-bottom":"50px"},attrs:{"label":"","prop":"email","rules":[
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input',{attrs:{"placeholder":"请输入邮箱地址"},on:{"focus":_vm.animateWidth},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.failed)?_c('div',{staticStyle:{"color":"#f56c6c","font-size":"12px","line-height":"1","padding-top":"4px","position":"absolute","top":"100%","left":"0"}},[_vm._v(" "+_vm._s(_vm.prompt)+" ")]):_vm._e(),(_vm.success)?_c('div',{staticStyle:{"color":"#67c23a","font-size":"12px","line-height":"1","padding-top":"4px","position":"absolute","top":"100%","left":"0"}},[_vm._v(" "+_vm._s(_vm.prompt)+" ")]):_vm._e()],1),_c('el-form-item',[_c('el-button',{staticClass:"log",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("发送邮件")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loginType),expression:"!loginType"}],staticClass:"scanCode"})],1),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginTitle"},[_c('img',{attrs:{"src":require("../../assets/imgs/logo.png"),"alt":""}}),_c('h2',[_vm._v("青梨AI科研服务平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box_left"},[_c('div',{staticClass:"bottom"},[_vm._v("北京盈科千信科技有限公司")]),_c('div',[_vm._v("地址：北京市海淀区中关村南大街48号")])]),_c('div',{staticClass:"splitLine"}),_c('div',{staticClass:"box_right"},[_c('div',{staticClass:"email"},[_vm._v("邮箱：product@increscence.net")]),_c('div',{staticClass:"record"},[_c('div',[_vm._v("Copyright © 2022")]),_c('img',{attrs:{"src":require("../../assets/imgs/badge.png"),"alt":""}}),_c('div',[_vm._v("京ICP备15054044号-1")])])])])])
}]

export { render, staticRenderFns }