<template>
  <div class="logins">
    <!-- 登录页面 -->
    <!-- <div class="leftImg">
    </div> -->
    <div class="loginTitle">
      <img src="../../assets/imgs/logo.png" alt="" />
      <h2>青梨AI科研服务平台</h2>
    </div>
    <!-- 账号密码登录 -->
    <div class="boxs">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        class="login-form"
        v-show="loginType"
      >
        <div class="formTop">
          <div class="login-title">邮件注册</div>
          <!-- <img
            @click="changeLoginType"
            src="../../assets/imgs/QRcode.png"
            alt=""
          /> -->
        </div>
        <el-form-item
          label=""
          prop="email"
          style="margin-bottom: 50px"
          :rules="[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input
            v-model="form.email"
            placeholder="请输入邮箱地址"
            @focus="animateWidth"
          ></el-input>
          <div
            v-if="failed"
            style="
              color: #f56c6c;
              font-size: 12px;
              line-height: 1;
              padding-top: 4px;
              position: absolute;
              top: 100%;
              left: 0;
            "
          >
            {{ prompt }}
          </div>
          <div
            v-if="success"
            style="
              color: #67c23a;
              font-size: 12px;
              line-height: 1;
              padding-top: 4px;
              position: absolute;
              top: 100%;
              left: 0;
            "
          >
            {{ prompt }}
          </div>
        </el-form-item>

        <!-- <el-form-item>
          <el-checkbox v-model="checked">下次自动登录</el-checkbox>
        </el-form-item> -->
        <el-form-item>
          <el-button class="log" type="primary" @click="submitForm('form')"
            >发送邮件</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 扫码登录 -->
      <div class="scanCode" v-show="!loginType"></div>
    </div>

    <!-- <ComFooter></ComFooter> -->
    <div class="container">
      <div class="box">
        <div class="box_left">
          <div class="bottom">北京盈科千信科技有限公司</div>
          <div>地址：北京市海淀区中关村南大街48号</div>
        </div>
        <div class="splitLine"></div>
        <div class="box_right">
          <div class="email">邮箱：product@increscence.net</div>
          <div class="record">
            <div>Copyright © 2022</div>
            <img src="../../assets/imgs/badge.png" alt="" />
            <div>京ICP备15054044号-1</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { registered } from "@/Api/login01";
import ComFooter from "@/components/ComFooter.vue";

import store from "@/store";

export default {
  data() {
    return {
      timer: "", // 定时任务
      // refreshLoding:true, // 刷新小程序二维码
      // loading: true,
      refreshQrcode: false, // 二维码已过期，点击刷新
      oper: "get", // get 获取小程序码    check 检测小程序码是否过期
      uid: "",
      qrcodeImg: "", // 微信小程序二维码图片
      loginType: true, // 登录方式 true：账号密码 false：微信扫码
      checked: false,
      form: {
        email: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { min: 3, max: 10, message: "用户名3-5位", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 3, max: 10, message: "密码3-5位", trigger: "blur" },
        ],
      },
      network: false,
      test: false,
      success: false,
      failed: false,
      prompt: "",
    };
  },
  created() {
    console.log("aa");
    console.log(this.$route.query.chatroom);
    
    return;
  },
  mounted() {
    //检查小程序二维码的状态
  },
  components: {
    ComFooter,
  },

  methods: {
    
    submitForm(formName) {
    //   console.log("aa");
    // console.log(this.$route.query.chatroom);
    // return
      this.success = false;
      this.failed = false;
      this.prompt = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let emails = {
            email: this.form.email,
            chatroom_id: this.$route.query.chatroom,
            need_name: 0,
            need_phone: 0,
            need_faculty: 0,
            need_jobnumber: 0,
            // admin_key: "",
          };
          registered(emails)
            .then((res) => {
              console.log("a", res);
              if (res.data.error_code == 1) {
                this.failed = true;
                this.prompt = res.data.msg;
                // this.$message({
                //   message: res.data.msg,
                //   type: "success",
                // });
              } else {
                this.success = true;
                this.prompt = res.data.msg;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    animateWidth() {
      this.success = false;
      this.failed = false;
      this.prompt = "";
    },
  },
};
</script>

<style  lang="less" scoped>
.logins {
  height: 100vh;
}
.loginTitle {
  height: 8%;
  // height: 85px;
  display: flex;
  align-items: center;
  // position: fixed;
  // padding-top: 3%;
  padding-left: 5%;
  img {
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }
  h2 {
    color: #333;
  }
}
.formTop {
  margin-bottom: 50px;
  display: flex;

  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    margin-right: -20px;
    margin-top: -10px;
  }
}
.leftImg {
  background-color: #fff;
  width: 500px;
  height: 380px;
  margin-right: 50px;
}
.boxs {
  background: url(../../assets/imgs/loginBanner.png) no-repeat left 74%;
  // background-repeat: repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 170px);
  // max-height: 1200px;
  height: 84%; 
}
.login-form {
  width: 320px;
  height: 300px;
  background-color: #ffffff;
  padding: 20px 30px;
  margin-left: 46%;
}

/* 标题 */
.login-title {
  color: #303133;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  width: 80%;
  //   margin-right: 44px;
}
.log {
  // margin-left: 80px;
  width: 100%;
}

.scanCode {
  width: 320px;
  height: 370px;
  background-color: #ffffff;
  padding: 20px 0px;
  text-align: center;
  margin-left: 46%;
}

.formTop2 {
  display: flex;
  margin-bottom: 25px;
  align-items: center;

  img {
    width: 60px;
    margin-right: 6px;
    margin-top: -8px;
  }
}
.qrcodeImgBox {
  text-align: center;
  height: 70%;
  .loginText {
    color: #333;
    margin-top: 20px;
    font-size: 16px;
  }
}
.mark {
  height: 204px;
}
.qrcodeImg {
  width: 200px;
}

.refreshQrcode {
  margin-top: -206px;
  height: 210px;
  width: 210px;
  margin-left: 55px;
  position: absolute;
  padding: 75px 0;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.internet {
  margin-top: -300px;
  height: 210px;
  width: 210px;
  margin-left: 55px;
  position: absolute;
  padding: 75px 0;
  // background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.container {
  height: 8%;
  // height: 85px;
  background-color: #f1f2f6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 10px;
}
.splitLine {
  width: 1px;
  height: 56px;
  background-color: #aaa;
  margin: 0px 20px;
}
.box_right img {
  width: 20px;
  margin: 0 5px;
}
.record {
  display: flex;
  align-items: center;
}
.bottom {
  margin-bottom: 20px;
  margin-left: 61px;
}
.email {
  margin-bottom: 14px;
}
.tap {
  color: rgb(103, 160, 247);
}
.tap:hover {
  color: rgb(0, 183, 255);
}
</style>